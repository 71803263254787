import { courses as types } from '@codesass/types';
import { graphql, PageProps } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

import Overview from 'modules/courses/components/Overview';

type OverviewPageProps = {
  coursesYaml: Pick<types.Course, 'title' | 'excerpt'> & {
    fields: {
      slug: types.Course['slug'];
    };
    image: ImageDataLike;
  };
};

const OverviewPage = ({
  data: {
    coursesYaml: {
      title,
      excerpt,
      image,
      fields: { slug },
    },
  },
}: PageProps<OverviewPageProps>) => {
  return (
    <Overview
      title={title}
      excerpt={excerpt}
      image={image}
      slug={slug}
    ></Overview>
  );
};

export const query = graphql`
  query CourseOverviewQuery($id: String) {
    coursesYaml(id: { eq: $id }) {
      fields {
        slug
      }
      title
      excerpt
      image {
        childImageSharp {
          gatsbyImageData(width: 500)
        }
      }
    }
  }
`;

export default OverviewPage;
