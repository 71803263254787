import {
  courses as coursesTypes,
  comments as commentsTypes,
} from '@codesass/types';
import { comments as api } from '@codesass/api';

import { useCallback } from 'react';

import Discussion from 'modules/comments/components/Discussion';

type CourseReviewsProps = Pick<coursesTypes.Course, 'slug'>;

const CourseReviews = ({ slug }: CourseReviewsProps) => {
  const saveComment = useCallback(
    ({
      rating,
      content,
    }: Pick<commentsTypes.Comment, 'rating' | 'content'>) => {
      api.addComment({
        type: 'lesson',
        courseSlug: slug,
        lessonSlug: 'overview',
        rating,
        content,
      });
    },
    [slug]
  );

  const loadDiscussion = useCallback(async () => {
    return api.getComments({
      type: 'lesson',
      courseSlug: slug,
      lessonSlug: 'overview',
    });
  }, [slug]);

  return (
    <Discussion
      noDiscussionText="ยังไม่มีรีวิว"
      placeholder="ระบุความคิดเห็นของคุณ"
      saveBtnTitle="รีวิว"
      hasRating={true}
      onSave={saveComment}
      onLoad={loadDiscussion}
    ></Discussion>
  );
};

export default CourseReviews;
