import {
  Typography,
  Box,
  Stack,
  Tabs,
  Tab,
  Link,
  CircularProgress,
} from '@mui/material';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { courses as types } from '@codesass/types';
import { courses as api } from '@codesass/api';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import TabPanel from 'modules/ui/components/TabPanel';

import { selectProfile } from 'modules/auth/selectors';

import CourseReviews from './CourseReviews';
import LessonError from './lessons/LessonError';

type OverviewProps = Pick<types.Course, 'slug' | 'title' | 'excerpt'> & {
  image: ImageDataLike;
};

const Overview = ({ title, excerpt, slug, image }: OverviewProps) => {
  const courseImage = getImage(image);
  const [resource, setResource] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState<string>();
  const profile = useSelector(selectProfile);

  const loadOverview = useCallback(async () => {
    setIsLoading(true);

    try {
      const overview = await api.getOverview(slug);
      setResource(overview.resource);
    } catch (error_) {
      if (error_ instanceof Error) setError(error_.message);
    }

    setIsLoading(false);
  }, [slug]);

  const changeTab = useCallback((_event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  useEffect(() => {
    loadOverview();
  }, [loadOverview]);

  if (isLoading) {
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      ></CircularProgress>
    );
  }
  if (error) return <LessonError error={error} slug={slug}></LessonError>;
  if (!profile) {
    return (
      <LessonError
        error="โปรดลงทะเบียนเพื่อเข้าถึงเนื้อหาของบทเรียนนี้"
        slug={slug}
      ></LessonError>
    );
  }

  return (
    <Stack m={2}>
      <Typography my={2} variant="h5" component="h1" textAlign="center">
        {title}
      </Typography>
      <Box alignSelf="center">
        {courseImage && <GatsbyImage image={courseImage} alt={title} />}
      </Box>
      <Typography my={2}>{excerpt}</Typography>
      <Tabs textColor="inherit" value={tabValue} onChange={changeTab}>
        <Tab label="ไฟล์ประกอบบทเรียน"></Tab>
        <Tab label="รีวิวคอร์ส"></Tab>
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        {isLoading ? (
          <CircularProgress></CircularProgress>
        ) : (
          <Typography>
            {resource ? (
              <>
                คุณสามารถดาวน์โหลดไฟล์ประกอบคอร์นี้ได้จาก{' '}
                <Link href={resource} color="inherit" target="_blank">
                  ที่นี่
                </Link>
              </>
            ) : (
              'คอร์สนี้ไม่มีไฟล์ประกอบคอร์ส'
            )}
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CourseReviews slug={slug}></CourseReviews>
      </TabPanel>
    </Stack>
  );
};

export default Overview;
